import React, { createContext, useState, useEffect } from "react"
import { useLocation } from "@reach/router"

const defaultState = {
  isFavorite: false,
  favList: [],
  addFavorite: () => {},
  removeFavorite: () => {},
}

const FavoriteContext = createContext(defaultState)

// Contextのプロバイダーを作成
export const FavoriteProvider = ({ children }) => {
  const [isFavorite, setIsFavorite] = useState(false)
  const [favList, setFavList] = useState([])
  const location = useLocation()
  const regex = /\/companies\/(.+?)\//
  const pathname = regex.exec(location.pathname)
  let currentSlug = null
  if (pathname) {
    currentSlug = pathname[1]
  }

  // localStorageからお気に入りのリストを取得する関数
  const getFavList = () => {
    const key = "favorites"
    const getjson = localStorage.getItem(key)
    let favlist = getjson !== null ? JSON.parse(getjson) : []
    return favlist
  }

  // localStorageにお気に入りのリストを保存する関数
  const saveFavList = (list) => {
    const key = "favorites"
    const setJson = JSON.stringify(list)
    localStorage.setItem(key, setJson)
  }

  // お気に入りにデータを追加する関数
  const addFavorite = (value) => {
    // valueはお気に入りに追加したいデータ
    let list = getFavList() // 現在のお気に入りのリストを取得
    list.push(value) // リストにvalueを追加
    saveFavList(list) // localStorageに保存
    setIsFavorite(true) // お気に入りの状態をtrueにする
    setFavList(list) // お気に入りのリストを更新する
  }

  // お気に入りからデータを削除する関数
  const removeFavorite = (id) => {
    // valueはお気に入りから削除したいデータ
    let list = getFavList() // 現在のお気に入りのリストを取得
    list = list.filter((fav) => fav.id !== id) // リストからvalueを削除
    saveFavList(list) // localStorageに保存
    setIsFavorite(false) // お気に入りの状態をfalseにする
    setFavList(list) // お気に入りのリストを更新する
  }

  // コンポーネントがマウントされたとき、localStorageからお気に入りのリストと状態を取得する
  useEffect(() => {
    let list = getFavList()
    setFavList(list)
    let check =
      list.length > 0 && currentSlug
        ? list.some((fav) => fav.id === currentSlug)
        : false
    setIsFavorite(check)
  }, [currentSlug])

  return (
    <FavoriteContext.Provider
      value={{ isFavorite, favList, addFavorite, removeFavorite }}
    >
      {children}
    </FavoriteContext.Provider>
  )
}

export default FavoriteContext
